<template lang="html">
  <div>
    <!-- <div class="padding-top-hook" :class="{'padding-top-hook-tall': hasSubMenu }"></div> -->
    <div class="podnav d-flex flex-column" :class="{'podnav-30': !hasSubMenu }">
      <div class="d-flex">
        <span 
          class="link_plan cursor-pointer send-fleet-toggler" 
          :class="{'opened': sendFleetState }" 
          @click="toggleSendFleet">Отправка флота</span>

        <SenderComponent 
          :send_fleet="send_fleet" 
          :disabl="namePage != 'Planets'" 
          :selectPlanet="selectPlanet" 
          type="scout" >Развед: </SenderComponent>

        <SenderComponent 
          :send_fleet="send_fleet" 
          :disabl="namePage != 'Planets'" 
          :selectPlanet="selectPlanet" 
          type="collector" >Коллeктор: </SenderComponent>

        <span 
          href="#"
          class="mr-2 mx-3 link_plan" 
          title="Антиматерия">
          <img 
            style="margin-bottom: 3px" 
            class="shadow" 
            src="@/assets/img/annihilation_reactor 1.png" alt=""> Am: <span ref="selectplanetamwrapper">{{ animatedNumberAm }}</span> </span>
        <span
          href="#"  
          class="mr-2 mx-3 link_plan" 
          title="Флоты" 
          v-if="isProVersion">Флоты: <span class="text-white">{{ usedFleets }} / {{ maxFleets }}</span></span>

        <span class="mr-auto ml-1 link_plan">
          <a href="https://warforgalaxy.com/store" target="_blank" class="text-white" v-html="licenseText"></a></span>


        <div class="main-nav">
          <a 
            href="#/reports" 
            class="maincolor mintext position-relative" 
            :class="{'active': namePage === 'Report'}" 
            @click="$emit('isPageShow', 1)" 
            title="">Отчеты
            <div class="curcl_mess" v-if="coutNotification>0 && namePage != 'Report' && coutNotificationLigth < 1">
              {{(coutNotification>99)?'99+':coutNotification}}
            </div>
            <div class="curcl_mess red" v-if="coutNotificationLigth > 0 && namePage != 'Report'">
              {{(coutNotificationLigth>99)?'99+':coutNotificationLigth}}
            </div>
          </a>
          <a 
            href="#/"  
            class="maincolor mintext" 
            :class="{'active': namePage === 'Planets'}" 
            @click="$emit('isPageShow', 0)" 
            title=""> Планеты </a>
          <a 
            href="#/cad"
            class=" maincolor mintext" 
            :class="{'active': namePage === 'Cad'}" 
            @click="$emit('isPageShow', 2)" 
            title=""> Задания </a>
          <a 
            href="#/simulator"  
            class="maincolor mintext" 
            :class="{'active': namePage === 'Simulator'}" 
            @click="$emit('isPageShow', 2)" 
            title=""> Симулятор </a>
          </div>

        <SelectPlanet :listPlanet="listPlanet" :selectPlanet="selectPlanet" :selectPlanetFunc="selectPlanetFunc" />
      </div>
      <div class="d-flex sub-menu" v-if="hasSubMenu">
        <div v-if="namePage == 'Cad'" class="col-2" ></div>
        <div v-if="namePage == 'Cad'" class="link_plan position-relative mx-auto cad-submenu">
          <a 
            @click.prevent="$emit('selectCad', 0)" 
            href="#" 
            :class="{'mt1':1, 'mr-3':1, select:numCad==0}"> <span class="maincolor mintext">Исследование</span></a>
          <a 
            @click.prevent="$emit('selectCad', 1)" 
            href="#" 
            :class="{'mt1':1, 'mr-auto':1, select:numCad==1}"> <span class="maincolor mintext">Маяк</span></a>
        </div>

        <a @click.prevent="newTask" v-if="namePage == 'Cad'" href="#" class="mt1"> <span class="maincolor mintext ">+ Создать задачу</span></a>
        <div v-if="namePage != 'Planets' && namePage != 'Cad'" class="wi100"></div>
      </div>
    </div>
  </div>
</template>

<script>
import SenderComponent from '@/components/planets/compon/SenderComponent.vue'
import SelectPlanet from '@/components/basic_component/SelectPlanet.vue'
import store from '@/store'
import { loop } from '@/helpers'
import { gsap } from "gsap"

const UPDATE_PLANETS_DELAY_MS = 15 * 1000
const UPDATE_NOTIF_DELAY_MS = 10 * 1000


// import SelectComponent from '@/components/basic_component/SelectComponent.vue'
export default {
  props:{
    numCad: Number,
  },
  components:{
    SenderComponent,
    SelectPlanet,
    // SelectComponent
  },
  created(){
    /**
     * 1. Загружаем список планет
     * 2. Ищем в хранилище selectPlanet
     * 3. Если selectPlanet есть в списке планет, то выбираем selectPlanet как выбранную
     * 4. Иначе выбираем первую планету из списка планет
     */

    // this.$watch('maxFleets', function (newValue) {
    //   gsap.to(this.$data, { duration: 0.5, tweenedMaxFleets: newValue })
    // })

    loop(() => {
      this.getListPlanet()
    }, UPDATE_PLANETS_DELAY_MS)

    loop(() => {
      this.getNumberNotification()
    }, UPDATE_NOTIF_DELAY_MS)

    // this.getListPlanet()
    // this.timerId = setInterval(() => {
    //   // wtf
    //   this.getListPlanet()
    // }, 30000);
 

    // let plan = false
    // try {
    //   plan = JSON.parse(localStorage.getItem('selectPlanet'))
    // } catch (err) {
    //   console.log(err)
    // }

    // if (plan) {
    //   this.selectPlanet = plan
    // }

    // this.numPlanet = localStorage.getItem('numPlanet')
    // this.idPlanet = localStorage.getItem('idPlanet')
    this.hasSubMenu = !!this.$router.currentRoute.meta.hasSubMenu
  },
  data(){
    return{
        nameUser:'',
        nameAlians:'',
        isCoorAdvMode:false,
        //token: '',
        selectPlanet: {},
        listPlanet:[],
        timerId: '',
        // numPlanet: 0,
        idPlanet: '',
        numGalaxy: '0',
        numSector: '0-50',
        numPlanets: '0-20',
        // numSector1: '',
        // numSector2: '',

        coutNotification: 0,
        coutNotificationLigth: 0,

        numGalaxy1: '0',
        numSector1: '0',
        numPlanet1: '0',
        numGalaxy2: '0',
        numSector2: '0',
        numPlanet2: '0',
        coardGaxlaxyArr: [0,0,0],
        coardGaxlaxyArr2: [0,0,0],
        hasSubMenu: false,
        isStoppedByFocus: false,
        tweenedAm: 0,
        tweenedUsedFleets: 0,
        tweenedMaxFleets: 0
    }
  },
  methods: {
    toggleFilter() {
      this.$store.commit('toggleFilterState')
    },
    toggleSendFleet() {
      if (this.$route.name !== 'Planets') {
        return 
      }

      this.$store.commit('toggleSendFleet')
    },
    hasObjectWithId(id, src) {
      for (let s of src) if (s.id === id) return true
      return false
    },
    getNumberNotificationLigth(){
      if (this.isStoppedByFocus) return

      // this.$axios.get("signals/messages/amount").then(res => {
      //   this.coutNotificationLigth = res.data.amount
      // }).catch(err => console.log(err))
    },
    getNumberNotification(){
      if (this.isStoppedByFocus) return

      this.getNumberNotificationLigth()
			this.$axios.get("info/reports/amount").then(res => {
        this.coutNotification = 0

				res.data.forEach(item => {
					if (item.type == 'sky'){
						this.coutNotification += item.amount}
					else{
            this.coutNotification += item.amount}
          })
			})
		},
    fock(id){
      this.isStoppedByFocus = true

			if (this[id] == '0') {
        this[id] = ''
			}
		},
		blr(id){
      this.isStoppedByFocus = false

      if (this[id] == '') {
        this[id] = 0
			}
    },
    activeIsCoorAdvMode(){
      this.isCoorAdvMode = !this.isCoorAdvMode
    },
    selectFun(val, num){
      if (num == 0) {this.numGalaxy1=val}
    },
    upSelect(up = false){
      if (this.numSector == '') {
        this.numSector = 0
      }
      if (up) {
        this.numSector += 1
      }else if (!up && this.numSector > 0) {
        this.numSector -= 1
      }
    },
    minusFleet(fleet, count, get = false){
			for (var i = 0; i < this.selectPlanet.fleet.length; i++) {
				if (this.selectPlanet.fleet[i].name == fleet) {
          if (get) {return this.selectPlanet.fleet[i].amount}
					if (this.selectPlanet.fleet[i].amount >= count) {
						this.selectPlanet.fleet[i].amount -= count
					}else {
						return -1
					}
					return this.selectPlanet.fleet[i].amount
				}
			}
		},
    send_fleet(type, count, auto){
      this.$emit('send_fleet', type, count, auto)
    },
    settingShow(){
      this.$emit('settingShow')
    },
    newTask(){
      this.$emit('newTask')
    },
    getPlanetFromId(id){
      for (var i = 0; i < this.listPlanet.length; i++) {
        if (this.listPlanet[i].id == id)
          return this.listPlanet[i]
      }
    },
    async getListPlanet(){
      const { data: json } = await this.$axios.get(`/user/planets`)
      if (json.items.length < 1) {
        return this.$notify({ type: 'error', text: 'Не удалось загрузить список планет' })
      }

      this.listPlanet = json.items
      this.$store.commit('setPlanets', json.items)

      let savedSelectedPlanet = {}
      try {
        savedSelectedPlanet = JSON.parse(window.localStorage.getItem('selectPlanet'))
        savedSelectedPlanet = this.getPlanetFromId(savedSelectedPlanet.id)
      } catch (err) {
        window.localStorage.removeItem('selectPlanet')
      }

      if (!savedSelectedPlanet || 
          savedSelectedPlanet.id === undefined ||
          !this.hasObjectWithId(savedSelectedPlanet.id, this.listPlanet)) {
        savedSelectedPlanet = this.listPlanet.slice(0, 1)[0]
      }

      this.selectPlanetFunc(savedSelectedPlanet)

      // this.$axios.get("user/planets").then(res=>{
      //   this.listPlanet = res.data.items
      //   if (!this.selectPlanet.name) {
      //     this.selectPlanetFunc(false, true)
      //   }else{
      //     this.selectPlanetFunc(this.idPlanet, true)
      //   }
      // })
    },
    //selectPlanetFunc(idPlanet, upGetListPlanet=false){
    selectPlanetFunc(selectedPlanetObj){
      this.selectPlanet = selectedPlanetObj
      this.$emit('selectPlanet', selectedPlanetObj)
      this.$store.commit('setSelectedPlanet', selectedPlanetObj)
      window.localStorage.setItem('selectPlanet', JSON.stringify(selectedPlanetObj))

      // if (!idPlanet) {
      //   this.selectPlanet = this.listPlanet[0]
      // } else {
      //   this.selectPlanet = this.getPlanetFromId(idPlanet)
      // }
      // this.idPlanet = idPlanet

      // if (!upGetListPlanet) {
      //   localStorage.setItem('idPlanet', idPlanet)
      // }

      // console.log(`Local idPlanet ${this.idPlanet}`)

      // this.$emit('selectPlanet', this.selectPlanet)
      // console.log(`selectPlanetFunc`, JSON.stringify(this.selectPlanet))
			// // this.numPlanet = planetNum
			// // this.selectPlanet = this.listPlanet[planetNum]
      // // localStorage.setItem('numPlanet', this.numPlanet)
      // if (!this.selectPlanet) this.selectPlanet = {}
      // localStorage.setItem('selectPlanet', JSON.stringify(this.selectPlanet))
		},
    formatCoard(){

      return this.numGalaxy<0?-1:this.numGalaxy, this.numSector1<0?-1:this.numSector1+'-'+this.numSector2<0?-1:this.numSector2, this.numPlanet1<0?-1:this.numPlanet1+'-'+this.numPlanet2<0?-1:this.numPlanet2
    },
    emitChangeGalaxy() {
      this.$emit('changeNumGalaxy')
    },
    getNavSubText() {
      return store.licenseInfo.available ? 
              `Pro-версия: ${store.licenseInfo.leftdays} дн.` :
              `ДЕМО-версия: КУПИТЬ PRO`
    },
    // getFleetText() {
    //   return `${store.getUsedFleets()}/${store.getMaxFleets()}`
    // }
  },
  computed:{
    sendFleetState() {
      return this.$store.getters.sendFleetState
    },
    galaxy(){
      return [...Array(1067).keys()]
    },
    sector(){
      return [...Array(24).keys()]
    },
    planets(){
      return [...Array(21).keys()]
    },
    namePage() {
      return this.$route.name
    },
    usedFleets() {
      return this.$store.getters.usedFleets
    },
    maxFleets() {
      return this.$store.getters.maxFleets
    },
    // fleetText() {
    //   return `${this.usedFleets}/${this.maxFleets}`
    // },
    isProVersion() {
      return this.$store.getters.isProVersion
    },
    licenseText() {
      return this.$store.getters.isProVersion ?
        `Pro-версия: ${this.$store.getters.licenseLeftDays} дн.` :
        `<span style="color: red; font-weight: bold" title="Все данные в КЦ не являются настоящими">ДЕМО-версия (данные ненастоящие): КУПИТЬ PRO</span>`
    },
    selectPlanetAm() {
      return this.selectPlanet.antimatter || 0
    },
    animatedNumberAm() {
      return this.tweenedAm.toFixed(0)
    },
    // animatedUsedFleets() {
    //   return this.tweenedUsedFleets.toFixed(0)
    // },
    // animatedMaxFleets() {
    //   return this.tweenedMaxFleets.toFixed(0)
    // }
  },
  watch:{
    // usedFleets(newValue) {
    //   gsap.to(this.$data, { duration: 0.5, tweenedUsedFleets: newValue })
    // },
    // maxFleets(newValue) {
    //   console.log(newValue)
    //   gsap.to(this.$data, { duration: 0.5, tweenedMaxFleets: newValue })
    // },
    selectPlanetAm(newValue) {
      gsap.to(this.$data, { duration: 0.5, tweenedAm: newValue })
    },
    nameUser(value){
      this.$emit("nameUser", value)
    },
    nameAlians(value){
      this.$emit("nameAlians", value)
    },
    numGalaxy(){
      this.$emit('changeNumGalaxy', this.numGalaxy<0?-1:this.numGalaxy, this.numSector<0?-1:this.numSector, this.numPlanets<0?-1:this.numPlanets )
    },
    numSector(){
      this.$emit('changeNumGalaxy', this.numGalaxy<0?-1:this.numGalaxy, this.numSector<0?-1:this.numSector, this.numPlanets<0?-1:this.numPlanets )
    },
    numPlanets(){
      this.$emit('changeNumGalaxy', this.numGalaxy<0?-1:this.numGalaxy, this.numSector<0?-1:this.numSector, this.numPlanets<0?-1:this.numPlanets )
    },
    numGalaxy1(value){
      if (value != '')
        this.$emit('changeNumGalaxy', this.numGalaxy1<0?-1:this.numGalaxy1, (this.numSector1<0?-1:this.numSector1)+'-'+(this.numSector2<0?-1:this.numSector2), (this.numPlanet1<0?-1:this.numPlanet1)+'-'+(this.numPlanet2<0?-1:this.numPlanet2) )
    },
    numSector1(value){
      if (value != '')
        this.$emit('changeNumGalaxy', this.numGalaxy1<0?-1:this.numGalaxy1, (this.numSector1<0?-1:this.numSector1)+'-'+(this.numSector2<0?-1:this.numSector2), (this.numPlanet1<0?-1:this.numPlanet1)+'-'+(this.numPlanet2<0?-1:this.numPlanet2) )
    },
    numSector2(value){
      if (value != '')
        this.$emit('changeNumGalaxy', this.numGalaxy1<0?-1:this.numGalaxy1, (this.numSector1<0?-1:this.numSector1)+'-'+(this.numSector2<0?-1:this.numSector2), (this.numPlanet1<0?-1:this.numPlanet1)+'-'+(this.numPlanet2<0?-1:this.numPlanet2) )
    },
    numPlanet1(value){
      if (value != '')
        this.$emit('changeNumGalaxy', this.numGalaxy1<0?-1:this.numGalaxy1, (this.numSector1<0?-1:this.numSector1)+'-'+(this.numSector2<0?-1:this.numSector2), (this.numPlanet1<0?-1:this.numPlanet1)+'-'+(this.numPlanet2<0?-1:this.numPlanet2) )
    },
    numPlanet2(value){
      if (value != '')
        this.$emit('changeNumGalaxy', this.numGalaxy1<0?-1:this.numGalaxy1, (this.numSector1<0?-1:this.numSector1)+'-'+(this.numSector2<0?-1:this.numSector2), (this.numPlanet1<0?-1:this.numPlanet1)+'-'+(this.numPlanet2<0?-1:this.numPlanet2) )
    },

  },
  // destroyed(){
  //   clearInterval(this.timerId1)
	// 	clearInterval(this.timerId)
	// },
}
</script>

<style lang="css" scoped>
.send-fleet-toggler {
  background: inherit;
  padding: 5px 10px !important;
  margin: 0 !important;
  user-select: none;
}

.send-fleet-toggler.opened {
  background: var(--nav-send-fleet-toggler-opened-bg);
  color: var(--nav-send-fleet-toggler-opened-color);
}

.curcl_mess{
  background: var(--nav-curcl-mess-bg);
  position: absolute;
  height: 15px;
  width: 15px;
  border-radius: 20px;
  top: 2px;
  right: -4px;
  padding-top: 2px;
  font-weight: 500;
  color: var(--nav-curcl-mess-color);
  font-size: 8px;
  text-align: center;
}
.curcl_mess.red{
  background: var(--nav-curcl-mess-red-bg);
}
.popupsSetting{
  box-shadow: 0px 0px 20px rgb(0 0 0 / 25%);
  padding: 0 10px 10px;
  position: absolute;
  background: var(--nav-popupsettings-bg);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  top: 26px;
}
select>option{
  /* color: black; */
  background: var(--nav-select-option-bg);
}
.muted{
  color: var(--nav-muted-color) !important;
}
.cpo{
  cursor: pointer;
}
.wi100{
  width: 108px;
}
.link_plan{
  color: var(--nav-link-plan-color);
  font-size: 12px;
  margin-top: 4px;
}
.maincolor:hover {
  color: var(--nav-link-plan-color);
}
.mintext{
  font-size: 13px;
}
.podnav {
  /* z-index: 10; */
  /* padding-left:5px;
  padding-right:35px; */
  /* position: fixed;
  left: 0;
  top: 0;
  width:100%;
  height: 60px;
  background: #2F3136;
  z-index: 10; */
  height: 60px;
  background: var(--nav-podnav-background);
  position: relative;
}

.podnav-30 {
  height: 30px;
}

.podnav > div {
  height: 30px;
}

.padding-top-hook {
  width: 100%;
  height: 30px;
}

.numInp{
  width: 80px;
  background: var(--nav-numinp-bg);
  border: 1px solid var(--nav-numinp-border-color);
  /* border: none; */
  color: var(--nav-numinp-color);
}
.numInp:focus{
  outline: none;
}
.numInp::-webkit-outer-spin-button,
.numInp::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.numInp[type=number] {
  -moz-appearance: textfield;
}

.main-nav {
  display: flex;
}

.main-nav a {
  padding: 5px 15px;
}

.main-nav a.active, .sub-menu {
  background: var(--nav-submenu-bg);
}
.select > span{
  color: var(--nav-select-span-color);
}

.main-nav a.active {
  color: var(--nav-select-span-color);
}

.sub-menu {
  padding: 0px 15px;
  display: flex;
  justify-content: flex-end;
}

.cad-submenu a {
  display: inline-block;
  padding: 1px 10px;
  /* //height: 100%; */
}

.cad-submenu a.select {
  background: var(--nav-select-span-color);
  border-radius: 3px;
}

.cad-submenu a.select span {
  color: var(--nav-select-submenu-span-color);
}

.cad-submenu .maincolor,
.sub-menu .maincolor {
  color: var(--cad-submenu-maincolor);
}

.cursor-pointer {
  cursor: pointer;
}

.link_plan {
  color: white;
}

[data-theme="light"] .link_plan {
  color: #000;
}
</style>
