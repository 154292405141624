<template id="">
<div :class="{sender:1, active:show, 'mr-2':1}">
  <div :class="{sender_first:1, bb:show}" @click.stop="show_panel()">
    <a style="cursor:pointer"  class="mx-3 link_plan hov "  title="Послать на выбранные планеты">
      <img v-if="type=='scout'" class="mr-1" style="margin-bottom: 3px" src="@/assets/img/scout 1.png" alt="">
      <img v-if="type=='collector'" class="mr-1" style="margin-bottom: 3px" src="@/assets/img/collector 1.png" alt="">
       <slot></slot> <span ref="countwrapper">{{ animatedNumber }}</span>
    </a>
  </div>
  <div v-if="show" class="sender_footer text-center text-white">
    <div class="bb sender_footer_count"  style="">
      <p class="mb-0 text-white text-center">
        Количество:
      </p>
      <input type="number" :class="{sender_footer_input:1, muted:auto}" @focus="auto=false" name="" :min="0"  :max="getscouandcol" v-model="count" placeholder="1">
      <p v-if="type=='collector'" class="mb-0 mt-2 muted2" style="font-size:10px;">Вместимость <span class="b">1</span> <br> коллектора <span class="b">20 000</span> </p>
    </div>
    <div class="" v-if="type=='collector'">
      <p class="m-0">
        Авто
        <label class="my-0">
          <input type="checkbox" v-model="auto" :disabled="type=='scout'" >
          <span  class="pseudocheckbox"></span>
        </label>
        <!-- <input type="checkbox" name="" value=""> -->
      </p>
    </div>

    <button @click="send(type, count)" type="button" class="button_sender" name="button">Отправить</button>
  </div>
</div>
</template>


<script type="text/javascript">
// import { blinkColor } from "@/helpers"
import { gsap } from "gsap"

export default {
  props:{
    send_fleet: Function,
    type: String,
    selectPlanet: Object,
    disabl: Boolean,
  },
  mounted(){
  },
  data(){
    return {
      show: false,
      count: 1,
      auto: this.type=='collector',
      tweenedNumber: 0
    }
  },
  methods:{
    send(type, count){
      if (this.getscouandcol < 1) {
        this.count = 0
        return
      }
      this.send_fleet(type, count, this.auto)
      this.show_panel()
    },
    onclick(e){
      // sender
      // console.log(document.querySelector('.sender_footer'));
        if (document.querySelector('.sender_footer') && !document.querySelector('.sender_footer').contains(e.target) ) {
          this.show = false
          document.removeEventListener('click', this.onClick);
        }
    },
    show_panel(){
      if (this.disabl) return ''
      this.show = !this.show
      if (this.show){
        document.addEventListener('click', this.onclick)
      }else {
        document.removeEventListener('click', this.onClick);
      }
    },

  },
  computed:{
    getscouandcol(){
      if (!this.selectPlanet.fleet) return 0
      for (var i = 0; i < this.selectPlanet.fleet.length; i++) {
        if(this.selectPlanet.fleet[i].name == this.type){
          return this.selectPlanet.fleet[i].amount
        }
      }
       return 0
    },
    animatedNumber() {
      return this.tweenedNumber.toFixed(0)
    }
  },
  watch:{
    count(value){
      if (value < 0) {
        this.count = 0
      }
      
      if (value > this.getscouandcol) {
        this.count = this.getscouandcol
      }
    },
    selectPlanet(){
      if (this.getscouandcol > 0) {
        this.count = 1
      }
    },
    getscouandcol(newValue) {
      gsap.to(this.$data, { duration: 0.5, tweenedNumber: newValue })
      // if (parseInt(newValue) !== parseInt(oldValue)) {
      //   blinkColor(this.$refs.countwrapper)
      // }
    }
  }
}
</script>
<style scoped="">
.muted{
  color: var(--sender-muted-color) !important;
}
.muted2{
  color: var(--sender-muted2-color) !important;
}
.mb1 {
    margin-bottom: 4px;
}
  .sender_footer_count{
    margin-top:5px;
    margin-bottom:10px;
    padding-bottom: 10px;
  }
  .bb{
    border-bottom: 1px solid var(--sender-bb-border-color);
  }
  .sender_first{
    height: 30px;
  }
  .sender{
    z-index: 1;
    height: min-content;
    position: relative;
    /* width: 200px; */
  }
  .sender.active{
    box-shadow: 0px 0px 20px var(--sender-sender-active-box);
    background: var(--sender-sender-active-bg);
  }
  .sender_footer{
    font-size: 13px;
    background: var(--sender-sender-active-bg);
  }
  .link_plan{
    color: var(--sender-linkplan-color);
    font-size: 12px;
    margin-top: 4px;
  }
  .link_plan.hov:hover{
    color: var(--sender-linkplan-hover);
    font-weight: 400;
  }
  .sender_footer_input{
    width: 70px;
    text-align: center;
    background: var(--sender-footer-input-bg);
    border: 1px solid var(--sender-footer-input-border-color);
    box-sizing: border-box;
    color: var(--sender-footer-input-color);
    padding: 3px;
    margin-top: 2px;
  }
  .sender_footer_input:focus{
    outline: none;
  }
  .sender_footer_input::-webkit-outer-spin-button,
  .sender_footer_input::-webkit-inner-spin-button {
      /* display: none; <- Crashes Chrome on hover */
      -webkit-appearance: none;
      margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
  }
  .button_sender{
    margin: 10px 0;
    border-radius: 1px;
    height: 25px;
    padding: 0 10px;
    padding-bottom: 5px;
    color: var(--sender-button-sender-color);
    background: var(--sender-button-sender-bg);
    border: 1px solid var(--sender-button-sender-border-color);
    box-sizing: border-box;
  }
  .button_sender:focus{
    outline: none;
  }
  .button_sender:hover{
    background: var(--sender-button-sender-hover-bg);
  }

  input[type=checkbox] { display: none; }

  .pseudocheckbox::before {
      content: "\00A0";
      display: inline-block;
      box-sizing: border-box;
      width: 17px;
      height: 17px;
      background-color: var(--sender-pseudo-bg);
      border: 2px solid var(--sender-pseudo-border-color);
      border-radius: 0px;
      vertical-align: baseline;
      text-align: center;
      font-size: 14px;
      line-height: 14px;
      font-weight: bold;
      color: var(--sender-pseudo-color);
      margin-bottom: 0;
      cursor: pointer;
  }

  input[type=checkbox]:checked + .pseudocheckbox::before { content: "\2713"; }

.link_plan {
  color: #FFF;
}

[data-theme="light"] .link_plan {
  color: #000;
}
</style>
