<template>
  <div class="selectPlanets" data-intro="#select-own-planet">
    <p class="m-0 point d-flex align-items-center " @click.stop="show_footer()" >
      <img src="@/assets/img/1920_icon_planet_click 1.png" class="mb1 mt-1" height="15px" alt="">
      <span class="maincolor mintext cap ml-1 mt-0" style="margin-bottom:2px;" >{{selectPlanet.name}}</span>
      <img src="@/assets/img/Arrow 6.png" class="mb1 ml-auto mt-1" height="5px;" width="8px"  alt="">
    </p>
    <div class="selectPlanets_foter" v-show="show">
      <div :class="{'selectPlanets_item':1, 'select':planet.name==selectPlanet.name}" v-for="(planet) in listPlanet" :key="'selector_planet_' + planet.id"  @click="selectPlanetFunc(planet); show_footer()">
        <p class="m-0"><img src="@/assets/img/1920_icon_planet_click 1.png" class="mb1 " height="15px" alt=""> <span class="maincolor mintext cap" >{{planet.name}} [{{ planet.path }}]</span></p>
        <p v-for="fleet in get_fleet(planet.fleet)" :key="fleet.name+planet.antimatter" class="m-0">
          <!-- <img v-if="fleet.name == 'scout'" src="@/assets/img/scout 1.png" class="mb1 " height="15px" alt=""> -->
          <!-- <img v-if="fleet.name == 'collector'" src="@/assets/img/collector 1.png" class="mb1 " height="15px" alt=""> -->
          <span v-if="fleet.name == 'scout'" class="maincolor2 mintext " style="margin-left: 20px;">Разведзонды: <span class="maincolor ">{{fleet.amount}}</span></span>
          <span v-if="fleet.name == 'collector'" class="maincolor2 mintext " style="margin-left: 20px;">Коллекторы: <span class="maincolor ">{{fleet.amount}}</span></span>
        </p>
        <p class="m-0">
          <!-- <img  src="@/assets/img/annihilation_reactor 1.png" class="mb1 " height="15px" alt=""> -->
          <span class="maincolor2 mintext " style="margin-left: 20px;">Am: <span class="maincolor ">{{planet.antimatter}}</span></span>
         </p>
        <!-- <p class="m-0"><img src="@/assets/img/collector 1.png" class="mb1 " height="15px" alt=""> <span class="maincolor2 mintext">Коллекторы: <span class="maincolor ">12568</span></span></p> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    listPlanet: Array,
    selectPlanet: Object,
    selectPlanetFunc: Function,
  },
  mounted(){

  },
  methods:{
    onclick(e){
      if ( e.target.className != 'selectPlanets' ) {
        if (document.querySelector('.selectPlanets_foter') && !document.querySelector('.selectPlanets_foter').contains(e.target) ) {
          this.show = false
          document.removeEventListener('click', this.onClick);
        }
      }
    },
    get_fleet(fleet){
      let arr = []
      fleet.forEach(item => {
        if(item.name == "collector" || item.name == "scout"){
          arr.push(item)
        }
      })
      return arr
    },
    show_footer(){
      this.show = !this.show
      if (this.show ){
        document.addEventListener('click', this.onclick)
      }else {
        document.removeEventListener('click', this.onClick);
      }

    },
  },
  data(){
    return{
      show: false,
      text: false,
    }
  },

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.mb1{
  margin-bottom: 5px;
}
.cap{
  text-transform: capitalize;
}
.point{
  cursor: pointer;
}
.selectPlanets{
  font-size: 12px;
  padding: 4px 10px;
  background: var(--splanet-select-planets-bg);
  min-width: 200px;
  position: relative;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */
  -khtml-user-select: none;    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;
}
.selectPlanets_foter{
  overflow-y: auto;
  max-height: 380px;
  position: absolute;
  top: 30px;
  left: 0;
  /* height: 100px; */
  width: 100%;
  background: var(--splanet-select-planet-foter);
  z-index: 99;
}
.selectPlanets_item{
  cursor: pointer;
  padding: 4px 10px;
  border-bottom: 2px solid var(--splanet-select-planet-item-border-color);
}
.selectPlanets_item:hover{
  background: var(--splanet-item-hover-bg);
}
.maincolor2{
  color: var(--splanet-maincolor-color);
}
.select{
  background: var(--splanet-select-bg);
}

[data-theme="light"] .selectPlanets img {
  filter: invert(0.7)
}
</style>
