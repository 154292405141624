<template lang="html">
  <div :class="{'container-fluid':1, main_cont:1, active:logshow, cupoiniter:!logshow}" @click.stop="showLog(true)">
  <!-- <div class="container-fluid main_cont active"> -->
    <div class="row h100 main_bg position-relative">
      <!-- <div class="col-12" style="height:50px;"></div> -->
      <div :class="{'col-2':1, grey_bg:1,  'd-flex':1, cupoiniter:logshow}"  @click.stop="showLog(false)">
        <p class=" logshow  text-center w-100">
          Лог действий:
        </p>
        <img src="@/assets/img/dableDownArrow.png" v-if='logshow' class="dableDownArrow" alt="">
      </div>



      <div :class="{col:1, non_main_cont:1, 'd-flex':1, 'align-items-end':0, 'flex-column-reverse':1,'pt-4':(logshow && load)}">
          <!-- <transition-group name="fade" class="w-100"> -->
            <p class="mb-0 bb w-100 item_cont" v-for="(log, index) in logsSort" :key="index">
              <img v-if="log.success" src="@/assets/img/Rectangle 67.png" class="rect mr-1" style="" title="Успех" alt="">
              <img v-if="!log.success" src="@/assets/img/Rectangle 67Red.png" class="rect mr-1" style="" title="Попытка не удалась" alt="">
              <span class="mx-2 grey-text">{{new Date(log.received*1000).toLocaleString("ru", {month: 'long', day: 'numeric', hour: 'numeric',minute: 'numeric',})}}</span>
              {{log.message}}
            </p>

            <div v-if="logshow" class="mb-0 w-100 item_cont pb-5 position-relative" style="" >
              <div v-if='logshow && load' class="loadingio-spinner-spin-1f3l6xenuug"><div class="ldio-5ziscl8jrkv">
                <div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div><div><div></div></div>
                <div><div></div></div><div><div></div></div><div><div></div></div></div>
              </div>
            </div>

          <!-- </transition-group> -->
      </div>

    </div>
  </div>

</template>

<script>
export default {
  created(){
    this.token = localStorage.getItem("glxtoken")
    this.getDopLogs2(1, true)
    // this.getLogs()
    this.timerId = setInterval(this.updateLogs, 10000);
  },
  mounted(){
    document.querySelector(".non_main_cont").addEventListener('scroll', ()=> {
      if (this.logshow) {
        this.checkAjaxLoading()
      }
    })
  },
  data(){
    return{
      logs: [],
      token: '',
      timerId: '',
      page: 1,
      logshow: false,
      ukey: 0,
      load: false,
      countLogUp: 0,
      end: false,
    }
  },
  methods: {
    upkey(){
      return this.ukey += 1
    },
    checkAjaxLoading(){
      let topCoord = document.querySelector('.item_cont:last-child').getBoundingClientRect().top;
      if (topCoord > 29 && !this.load) {
        this.getDopLogs2(5)
      }
    },
    showLog(showl=false){
      if (showl && !this.logshow || !showl && !this.logshow) {
        this.logshow = true
        this.page = 1
        this.getDopLogs2(25)
      }else if(!showl){
        // this.countLogUp = 0
        this.page = 1
        this.end = false
        this.getDopLogs2(1, true)
        this.logshow = false
      }
    },
    updateLogs(){
      if (!this.logshow) {
        this.countLogUp = -1
        this.getLogs(true)
      }
    },
    getDopLogs2(count=1, firstPage=false){
      this.load = true
      if ((this.countLogUp < 0 && this.logs.length < 10) || this.end) {return}
      this.countLogUp += -1*(count)

      for (var i = 0; i < count; i++) {
        // console.log('count:', i);
        this.page += 1
        this.getLogs(firstPage)
      }
    },
    getLogs(firstPage = false){
      if (this.end) {return}
      let pg = firstPage?1:this.page
      // console.log("/log?page="+pg);
      this.$axios.get("/log?page="+pg).then(res=>{
        this.countLogUp+=1

        if(res.data.data.length<5){
          this.end = true
        }

        // console.log('Logs:',res.data, this.end);
        if (this.page==1 || firstPage) {
          this.logs = res.data.data
        }else {
          this.logs = this.logs.concat(res.data.data)
        }

        if (this.countLogUp>-1) {
          this.load = false
        }
      })

    },
  },
  computed:{
    logsSort(){
      return this.logs.slice().sort(function(a, b) {
        return b.received - a.received
      })
    },
  },
  destroyed(){
		clearInterval(this.timerId)
	},

}
</script>

<style lang="css" scoped>

.item_cont{
  opacity: 1;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
  opacity: 0;
}
.cupoiniter{
  cursor: pointer;
}
.grey-text{
  color: var(--log-grey-text-color);
}
.bb{
  border-bottom: 1px solid var(--log-bb-border-color);
}
.rect{
  height: 9px;
  width: 9px;
  margin-bottom:2px;
}
.h100{
  height: 100%;
}
.dableDownArrow{
  transform: translate(-50%, -50%);
  height: 30px;
  width: 30px;
  position: absolute;
  top: 50%;
  left: 50%;
}
.main_cont.active > .main_bg > .grey_bg > .logshow{
  margin-top: 5px!important;
}
.logshow{
  margin: auto 0 auto 0;
}
.main_cont.active > .main_bg > .non_main_cont{
  height: 100%!important;
}
.main_cont.active{
  transition: height 0.2s cubic-bezier(0.4, 0, 1, 1);
  z-index: 99;

  position: absolute;
  bottom: 0px;
  height: calc(100% - 30px);
}
.main_cont{
  width: 100%;
  max-width: 1390px;
  font-size: 15px;
  height: 55px;
  color: var(--log-main-cont-color);
  bottom: 0px;
  position: sticky;
}
.grey_bg{
  box-shadow: 0px -5px 10px var(--log-grey-box-shadow);
  background: var(--log-grey-bg);
  max-width: 150px;
}
.main_bg{
  background: var(--log-main-bg);
}
.logsBody{
  overflow-y: auto;
  width: 100%;
  max-height: 100%;
}
.non_main_cont{
  position: relative;
  overflow-y: scroll;
  height: 50px;
  box-shadow: 0px -5px 10px var(--log-non-main-cont-box-shadow);
  padding-top: 5px;
  font-size: 13px;
  color: var(--log-non-main-cont-color);
  background: var(--log-non-main-cont-bg)
}


@keyframes ldio-5ziscl8jrkv {
  0% {
    opacity: 1;
    backface-visibility: hidden;
    transform: translateZ(0) scale(10.33,10.33);
  } 100% {
    opacity: 0;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1,1);
  }
}
.ldio-5ziscl8jrkv div > div {
  position: absolute;
  width: 2px;
  height: 2px;
  border-radius: 50%;
  background: #ffffff;
  animation: ldio-5ziscl8jrkv 0.6097560975609756s linear infinite;
}.ldio-5ziscl8jrkv div:nth-child(1) > div {
  left: 81px;
  top: 49px;
  animation-delay: -0.5335365853658536s;
}
.ldio-5ziscl8jrkv > div:nth-child(1) {
  transform: rotate(0deg);
  transform-origin: 82px 50px;
}.ldio-5ziscl8jrkv div:nth-child(2) > div {
  left: 72px;
  top: 72px;
  animation-delay: -0.45731707317073167s;
}
.ldio-5ziscl8jrkv > div:nth-child(2) {
  transform: rotate(45deg);
  transform-origin: 73px 73px;
}.ldio-5ziscl8jrkv div:nth-child(3) > div {
  left: 49px;
  top: 81px;
  animation-delay: -0.3810975609756097s;
}
.ldio-5ziscl8jrkv > div:nth-child(3) {
  transform: rotate(90deg);
  transform-origin: 50px 82px;
}.ldio-5ziscl8jrkv div:nth-child(4) > div {
  left: 26px;
  top: 72px;
  animation-delay: -0.3048780487804878s;
}
.ldio-5ziscl8jrkv > div:nth-child(4) {
  transform: rotate(135deg);
  transform-origin: 27px 73px;
}.ldio-5ziscl8jrkv div:nth-child(5) > div {
  left: 17px;
  top: 49px;
  animation-delay: -0.22865853658536583s;
}
.ldio-5ziscl8jrkv > div:nth-child(5) {
  transform: rotate(180deg);
  transform-origin: 18px 50px;
}.ldio-5ziscl8jrkv div:nth-child(6) > div {
  left: 26px;
  top: 26px;
  animation-delay: -0.1524390243902439s;
}
.ldio-5ziscl8jrkv > div:nth-child(6) {
  transform: rotate(225deg);
  transform-origin: 27px 27px;
}.ldio-5ziscl8jrkv div:nth-child(7) > div {
  left: 49px;
  top: 17px;
  animation-delay: -0.07621951219512195s;
}
.ldio-5ziscl8jrkv > div:nth-child(7) {
  transform: rotate(270deg);
  transform-origin: 50px 18px;
}.ldio-5ziscl8jrkv div:nth-child(8) > div {
  left: 72px;
  top: 26px;
  animation-delay: 0s;
}
.ldio-5ziscl8jrkv > div:nth-child(8) {
  transform: rotate(315deg);
  transform-origin: 73px 27px;
}
.loadingio-spinner-spin-1f3l6xenuug {
  z-index: 999999;
  position: absolute;
  top: 10px;
  left: 50%;
  width: 44px;
  height: 44px;
  display: inline-block;
  overflow: hidden;
  background: rgba(221, 221, 221, 0);
}
.ldio-5ziscl8jrkv {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(0.44);
  backface-visibility: hidden;
  transform-origin: 0 0; /* see note above */
}
.ldio-5ziscl8jrkv div { box-sizing: content-box; }

[data-theme="light"] .dableDownArrow {
  filter: invert(1);
}
</style>
